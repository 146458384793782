<template>
  <Navbar />
  <div class="container">
    <iframe :v-if="ShowCard" :src="this.Token" id="paytriframe" frameborder="0" scrolling="yes" style="width: 100%;"
      :style="{ 'height': windowHeight + 'px' }"></iframe>
  </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
import Navbar from "../navbar.vue";
export default {
  metaInfo: {
    script: [
      { src: 'https://www.paytr.com/js/iframeResizer.min.js', async: true, defer: true }
    ],
  },
  data() {
    return {
      Token: "",
      heightFromScript: '40rem',
      ShowCard: false,
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    InitServis() {
      console.log(this.$route.query.OdemeKod);
      if (this.$route.query.OdemeKod == "" || typeof(this.$route.query.OdemeKod) === "undefined") {
        this.$router.push({ path: "/NotFound" })
      }

      let data = {
        OdemeKod: this.$route.query.OdemeKod,
      };
      GlobalServis.GlobalServis("POST", "GetOdemeToken", data).then(
        (res) => {
          if (res.status == 200) {
            this.Token = "https://www.paytr.com/odeme/guvenli/" + res.data;
            this.ShowCard = true;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Uyarı",
              detail: "Takip numaranız hatalıdır",
              life: 5000,
            });
          }
        }
      )
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
  },
  created() {
    // this.iFrameResize({},'#paytriframe');
    this.InitServis();

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  components: {
    Navbar
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}

// .container {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   background-color: #cdd4cd;
// }

// .centered {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-transform: translate(-50%, -50%);
//   -moz-transform: translate(-50%, -50%);
//   -o-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   height: v-bind('heightFromScript')
// }</style>